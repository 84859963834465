import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Footer from '../components/footer'
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaY } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { PiSealQuestion } from "react-icons/pi";
import { CiGlobe } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import LoadingScreen from '../components/loading';
import logo from "../imgs/logo.png"

function End() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center justify-content-around" style={{ height: '100svh', overflow: 'hidden' }}>

                <img className='main-page-logo' src={logo} />

                <MDBRow className='mt-5 end-page-title-wrapper'>
                    <MDBCol>
                        <h1 tag='h1' className='mb-2 end-page-title'>Muito obrigado por responder ao questionário!</h1>
                        <h2 className='mb-10 end-page-subtitle'>As ideias e opiniões de vocês são super importantes para nós. Vamos  juntos continuar trabalhando pra deixar São Paulo ainda mais incrível!</h2>
                    </MDBCol>
                </MDBRow>

                <MDBRow className='ms-3 me-3'>
                    <h4 className='mb-3 buttons-title'>Participe da nossa comunidade para ficar por dentro das últimas novidades!</h4>
                    <MDBCol className='d-flex flex-column ms-4 me-4'>
                        <a href='https://chat.whatsapp.com/GMYVcPrdWs68VgjiodcFkn' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main-wpp'>
                                <FaWhatsapp /> Junte-se à comunidade
                            </MDBBtn>
                        </a>
                    </MDBCol>
                </MDBRow>

                <div className="social-links-end">
                    <a href='https://instagram.com/rt_ricardoteixeira?igshid=NTc4MTIwNjQ2YQ=='><FaInstagram /></a>
                    <a href='https://www.facebook.com/paginaricardoteixeira?mibextid=ZbWKwL'><FaFacebookF /></a>
                    <a href='https://twitter.com/rteixeira_'><FaXTwitter /></a>
                    <a href='https://www.linkedin.com/in/ricardoteixeiraoficial/'><FaLinkedinIn /></a>
                    <a href='https://www.youtube.com/@RicardoTeixeiraOficial/playlists'><FaYoutube /></a>
                </div>
            </MDBContainer>
            <Footer />
        </>
    );
}

export default End