import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBCard } from 'mdb-react-ui-kit';
import AuthContext from '../controllers/authContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await login(username, password);
      console.log('Login result:', result);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <MDBContainer fluid className='login-container'>
      <MDBCard className='login-card'>
        <MDBRow className='justify-content-center'>
          <MDBCol md='12' className='login-wrapper'>
            <form onSubmit={handleSubmit} className='d-flex justify-content-center flex-column login-form'>
              <h1>Acesso ao Dashboard</h1>
              <label>Insira seus dados de login</label>
              <MDBInput
                label='Username'
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <MDBInput
                label='Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type='submit' className='mt-3 login-button'>
                Login
              </button>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
};

export default Login;
