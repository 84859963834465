import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Footer from '../components/footer'
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaY } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { PiSealQuestion } from "react-icons/pi";
import { CiGlobe } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import LoadingScreen from '../components/loading';
import logo from "../imgs/logo.png"

function Index() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center">

                <img className='main-page-logo' src={logo} />

                <MDBRow className='main-page-title-wrapper'>
                    <MDBCol>
                        <MDBTypography tag='h1' className='text-center mb-2 main-page-title'>Bem vindo!</MDBTypography>
                        <h2 className='main-page-subtitle'>Nos ajude a melhorar a faixa azul respondendo um simples questionário!</h2>
                    </MDBCol>
                </MDBRow>

                <div className="social-links">
                    <a href='https://instagram.com/rt_ricardoteixeira?igshid=NTc4MTIwNjQ2YQ=='><FaInstagram /></a>
                    <a href='https://www.facebook.com/paginaricardoteixeira?mibextid=ZbWKwL'><FaFacebookF /></a>
                    <a href='https://twitter.com/rteixeira_'><FaXTwitter /></a>
                    <a href='https://www.linkedin.com/in/ricardoteixeiraoficial/'><FaLinkedinIn /></a>
                    <a href='https://www.youtube.com/@RicardoTeixeiraOficial/playlists'><FaYoutube /></a>
                </div>
                <MDBRow className='ms-3 me-3'>
                    <h4 className='mb-2 buttons-title'>Utilize os botões abaixo para navegar!</h4>
                    <h3 className='mb-4 buttons-subtitle'>Faça o questionário e nos ajude a melhorar a faixa azul, visite nosso site e veja as novidades ou junte-se a comunidade e participe ativamente!</h3>
                    <MDBCol className='d-flex flex-column ms-4 me-4'>
                        <a href='/questionario' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main'>
                                <PiSealQuestion /> Faça o questionário
                            </MDBBtn>
                        </a>

                        <a href='https://www.vereadorricardoteixeira.com.br' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main'>
                                <CiGlobe /> Visite nosso site
                            </MDBBtn>
                        </a>

                        <a href='https://chat.whatsapp.com/GMYVcPrdWs68VgjiodcFkn' className='d-flex justify-content-center'>
                            <MDBBtn tag='a' color='warning' className='mb-4 buttons-main-wpp'>
                                <FaWhatsapp /> Junte-se à comunidade
                            </MDBBtn>
                        </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </>
    );
}

export default Index