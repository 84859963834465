import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from "../controllers/api-controller";
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import AuthContext from '../controllers/authContext';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [filters, setFilters] = useState({
    q1: '',
    q2: '',
    q5: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    applyFilters();
  }, [data, filters, searchId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, searchId]);

  const fetchData = async () => {
    try {
      const response = await axios.get((api) + '/get-answers', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setData(response.data);
    } catch (error) {
      console.error('Fetch data error:', error);
    }
  };

  const applyFilters = () => {
    let tempData = data;

    if (filters.q1) {
      tempData = tempData.filter(item => item.q1 === filters.q1);
    }

    if (filters.q2) {
      tempData = tempData.filter(item => item.q2 === filters.q2);
    }

    if (filters.q5) {
      tempData = tempData.filter(item => item.q5 === filters.q5);
    }

    if (searchId) {
      tempData = tempData.filter(item => item.id.toString().includes(searchId));
    }

    setFilteredData(tempData);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const handleSearchChange = (e) => {
    setSearchId(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const processData = () => {
    const total = data.length;
    const q1Yes = data.filter(item => item.q1 === 'Sim').length;
    const q1No = data.filter(item => item.q1 === 'Nao').length;
    const q2Yes = data.filter(item => item.q2 === 'Sim').length;
    const q2No = data.filter(item => item.q2 === 'Nao').length;
    const q5Yes = data.filter(item => item.q5 === 'Sim').length;
    const q5No = data.filter(item => item.q5 === 'Nao').length;

    return { total, q1Yes, q1No, q2Yes, q2No, q5Yes, q5No };
  };

  const { total, q1Yes, q1No, q2Yes, q2No, q5Yes, q5No } = processData();

  const barData = {
    labels: ['Q1', 'Q2', 'Q5'],
    datasets: [
      {
        label: 'Sim',
        data: [q1Yes, q2Yes, q5Yes],
        backgroundColor: '#FDC40050',
      },
      {
        label: 'Não',
        data: [q1No, q2No, q5No],
        backgroundColor: '#0005',
      },
    ],
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`dashboard-page-num ${i === currentPage ? 'active' : 'dashboard-page-num-selected'}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <MDBContainer fluid className='mb-4'>
      <MDBRow>
        <MDBCol md={4} className='dashboard-total-wrapper'>
          <h1 className='dashboard-total'>Total de envios: {total}</h1>
        </MDBCol>
        <MDBCol md={8}>
          <h3>Respostas por pergunta (Sim/Não)</h3>
          <Bar data={barData} />
        </MDBCol>
      </MDBRow>
      <MDBRow className='my-3'>
        <h3>Filtros</h3>
        <MDBCol md={3}>
          <label>Pesquise por algum número de ID específico </label>
          <MDBInput label="Buscar por ID" type="text" value={searchId} onChange={handleSearchChange} />
        </MDBCol>
        <MDBCol md={3} className='d-flex flex-column'>
          <label>Você conhece a faixa azul? </label>
          <select label="Filtrar por Q1" type="select" name="q1" value={filters.q1} onChange={handleFilterChange}>
            <option value="">Tudo</option>
            <option value="Sim">Sim</option>
            <option value="Nao">Não</option>
          </select>
        </MDBCol>
        <MDBCol md={3} className='d-flex flex-column'>
          <label>Você utiliza a faixa azul? </label>
          <select label="Filtrar por Q2" type="select" name="q2" value={filters.q2} onChange={handleFilterChange}>
            <option value="">Tudo</option>
            <option value="Sim">Sim</option>
            <option value="Nao">Não</option>
          </select>
        </MDBCol>
        <MDBCol md={3} className='d-flex flex-column'>
          <label>Você acredita que a faixa azul poderia melhorar? </label>
          <select label="Filtrar por Q5" type="select" name="q5" value={filters.q5} onChange={handleFilterChange}>
            <option value="">Tudo</option>
            <option value="Sim">Sim</option>
            <option value="Nao">Não</option>
          </select>
        </MDBCol>
      </MDBRow>
      <MDBContainer fluid className='desktop'>
        <MDBRow className='dashboard-questions-wrapper'>
          <MDBCol md={1}>ID</MDBCol>
          <MDBCol md={1}>Você conhece a faixa azul?</MDBCol>
          <MDBCol md={1}>Você utiliza a faixa azul?</MDBCol>
          <MDBCol md={2}>Em quais locais você utiliza faixa azul?</MDBCol>
          <MDBCol md={2}>Você gostaria da faixa azul em quais locais?</MDBCol>
          <MDBCol md={1}>Você acredita que a faixa azul poderia melhorar?</MDBCol>
          <MDBCol md={2}>Se sim, como a faixa azul poderia melhorar?</MDBCol>
          <MDBCol md={2}>Qual é o pior problema enfrentado no trânsito entre os carros e as motos?</MDBCol>
        </MDBRow>
        <MDBRow className='m-0 dashboard-answers-container'>
          {paginatedData.map((item, index) => (
            <MDBRow className='p-0 dashboard-item' key={index}>
              <MDBRow className='dashboard-answers-wrapper'>
                <MDBCol md={1} className='p-0'>{item.id}</MDBCol>
                <MDBCol md={1} className='p-0'>{item.q1}</MDBCol>
                <MDBCol md={1} className='p-0'>{item.q2}</MDBCol>
                <MDBCol md={2} className='p-0'>{item.q3}</MDBCol>
                <MDBCol md={2} className='p-0'>{item.q4}</MDBCol>
                <MDBCol md={1} className='p-0'>{item.q5}</MDBCol>
                <MDBCol md={2} className='p-0'>{item.q6}</MDBCol>
                <MDBCol md={2} className='p-0'>{item.q7}</MDBCol>
              </MDBRow>
            </MDBRow>
          ))}
        </MDBRow>
        <MDBRow className='my-4'>
          <MDBCol className='d-flex justify-content-center'>
            <button
              className='dashboard-button'
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            {renderPagination()}
            <button
              className='dashboard-button'
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
            >
              Próxima
            </button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className='mobile'>
        <MDBRow className='m-0 dashboard-answers-container'>
          {paginatedData.map((item, index) => (
            <MDBRow className='p-0 dashboard-item mb-2' key={index}>
              <MDBCol sd={4} className='dashboard-answers-wrapper'>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    ID
                  </MDBCol>
                  <MDBCol size="10">
                    {item.id}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q1
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q1}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q2
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q2}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q3
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q3}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q4
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q4}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q5
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q5}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q6
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q6}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='p-0 dashboard-m-answer'>
                  <MDBCol size="2" className='dashboard-m-indicator'>
                    Q7
                  </MDBCol>
                  <MDBCol size="10">
                    {item.q7}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          ))}
        </MDBRow>
        <MDBRow className='my-4'>
          <MDBCol md={12} className='pb-2 d-flex justify-content-center'>
            {renderPagination()}
          </MDBCol>
          <MDBCol md={12} className='d-flex justify-content-center'>
            <button
              className='dashboard-button'
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            <button
              className='dashboard-button'
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
            >
              Próxima
            </button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Dashboard;
