import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';

export default function App() {
    const [openBasic, setOpenBasic] = useState(false);

    return (
        <MDBContainer fluid className='footer-container' >
            <h2>Ricardo Teixeira © 2024. Todos os direitos reservados.</h2>
        </MDBContainer>
    );
}