import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6
import { MDBContainer, MDBBtn, MDBIcon, MDBTypography, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Footer from '../components/footer'
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaY } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { PiSealQuestion } from "react-icons/pi";
import { CiGlobe } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { api } from "../controllers/api-controller";
import logo from "../imgs/logo.png"
import LoadingScreen from '../components/loading';

function Questionary() {
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [answers, setAnswers] = useState({});
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleNext = () => {
        setCurrentQuestion(currentQuestion + 1);
    };

    const handlePrevious = () => {
        setCurrentQuestion(currentQuestion - 1);
    };

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        // Save the answer to the answers state
        setAnswers({ ...answers, [name]: value });

        // Move to the next question automatically
        setCurrentQuestion(currentQuestion + 1);
    };

    const handleTextAreaChange = (e) => {
        const { name, value } = e.target;
        setAnswers({ ...answers, [name]: value });
    };

    const handleSubmit = async () => {
        setIsSubmitting(true); // Set isSubmitting to true when the submission starts
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios.post(api + '/api/answers', answers, config);
            console.log(answers);
            console.log('Response from backend:', response.data);
            navigate('/final');
        } catch (error) {
            console.error('Error sending data:', error);
        } finally {
            setIsSubmitting(false); // Set isSubmitting to false when the submission ends
        }
    };

    const renderTimelineBalls = () => {
        const balls = [];
        for (let i = 1; i <= 7; i++) {
            balls.push(
                <div
                    key={i}
                    className={`timeline-ball ${i < currentQuestion ? 'previous' : ''} ${i <= currentQuestion ? 'filled' : ''}`}
                    onClick={() => setCurrentQuestion(i)}
                ></div>
            );
        }
        return balls;
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            const handleLoad = () => {
                setLoading(false);
            };

            window.addEventListener('load', handleLoad);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('load', handleLoad);
        }
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }


    return (
        <>
            <MDBContainer fluid className="p-0 d-flex flex-column align-items-center" style={{ minHeight: '100vh' }}>
                <img className='qa-page-logo' src={logo} />

                <MDBRow className='main-page-title-wrapper'>
                    <MDBCol>
                        <MDBTypography tag='h1' className='mt-2 mb-2 qa-page-title'>Agradecemos pelo seu interesse em ajudar a melhorar ainda mais a faixa azul!</MDBTypography>
                        <p className='qa-page-subtitle'>Responda as perguntas abaixo para prosseguir, por gentileza</p>
                    </MDBCol>
                </MDBRow>
                {/* Render current question based on currentQuestion state */}
                {currentQuestion === 1 && (
                    <>
                        <MDBContainer className='qa-container'>
                            <label className='question'>Você conhece a faixa azul?</label>
                            <MDBRow style={{ width: '100%' }}>
                                <MDBCol className='qa-buttons-wrapper'>
                                    <button className='qa-button-l' type="button" name="q1" value="Nao" onClick={handleAnswerChange} > Não </button>
                                    <button className='qa-button-r' type="button" name="q1" value="Sim" onClick={handleAnswerChange} > Sim </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </>
                )}
                {currentQuestion === 2 && (
                    <>
                        <MDBContainer className='qa-container'>
                            <label className='question'>Você utiliza a faixa azul?</label>
                            <MDBRow style={{ width: '100%' }}>
                                <MDBCol className='qa-buttons-wrapper'>
                                    <button className='qa-button-l' type="button" name="q2" value="Nao" onClick={handleAnswerChange} > Não </button>
                                    <button className='qa-button-r' type="button" name="q2" value="Sim" onClick={handleAnswerChange} > Sim </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </>
                )}
                {currentQuestion === 3 && (
                    <MDBContainer className='qa-container'>
                        <label className='question'>Em quais locais você utiliza faixa azul?</label>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q3"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
                {currentQuestion === 4 && (
                    <MDBContainer className='qa-container'>
                        <label className='question'>Você gostaria da faixa azul em quais locais?</label>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q4"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
                {currentQuestion === 5 && (
                    <>
                        <MDBContainer className='qa-container'>
                            <label className='question'>Você acredita que a faixa azul poderia melhorar?</label>
                            <MDBRow style={{ width: '100%' }}>
                                <MDBCol className='qa-buttons-wrapper'>
                                    <button className='qa-button-l' type="button" name="q5" value="Nao" onClick={handleAnswerChange} > Não </button>
                                    <button className='qa-button-r' type="button" name="q5" value="Sim" onClick={handleAnswerChange} > Sim </button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </>
                )}
                {currentQuestion === 6 && (
                    <MDBContainer className='qa-container'>
                        <label className='question'>Se sim, como a faixa azul poderia melhorar?</label>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q6"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input className='qa-button-r' type="button" value="Próximo" onClick={handleNext} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}
                {currentQuestion === 7 && (
                    <MDBContainer className='qa-container'>
                        <label className='question'>Qual é o pior problema enfrentado no trânsito entre os carros e as motos?</label>
                        <textarea
                            className='mt-2 qa-textarea'
                            name="q7"
                            rows={6}
                            onChange={handleTextAreaChange}
                        />
                        <MDBRow style={{ width: '100%' }}>
                            <MDBCol className='qa-buttons-wrapper'>
                                <input
                                    className='qa-button-next'
                                    type="button"
                                    value={isSubmitting ? "Enviando..." : "Enviar"}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting} // Disable the button when submitting
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )}

                {/* <button onClick={handlePrevious} disabled={currentQuestion === 1}>Previous</button>
                {currentQuestion < 7 ? (
                    <button onClick={handleNext}>Next</button>
                ) : (
                    <button onClick={handleSubmit}>Submit</button>
                )} */}

                {/* Timeline component */}
                <div className="timeline">
                    {renderTimelineBalls()}
                </div>
            </MDBContainer>
            <Footer />
        </>
    );
}

export default Questionary;
