import { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from "../controllers/api-controller";

export const useAuth = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setUser({ token });
        }
    }, []);

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${api}/api/login`, { username, password });
            const { token } = response.data;
            localStorage.setItem('token', token);
            setUser({ token });
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return { user, login, logout };
};
